import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class RyukyuKenpoKarateDoGaiyo extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Ryukyu kenpo karate-do gaiyo - Chojun Miyagi</h1>
                <div className="libraryImages">
                    <StaticImage src="../../images/Ryukyu-kenpo-karate-do-gaiyo.jpg" alt="Sanchin"/>
                </div>

                <sub>Translated by San Zinsoo. This article is a translation of an essay prepared by Miyagi and
                    presented to club members at the time of his "About Karate-do" lecture and demonstration at the
                    lecture hall on the 4th floor of Meiji Shoten on 28th November 1936. Miyagi is one of Okinawa's most
                    famous and influential karate pioneers and the founder of Goju Ryu karate.</sub>

                <ol>
                    <li>What is karate?</li>

                    <p>It is the art we exercise mind and body for health promotion in daily life, but in case of
                        emergency it is the art of self-defense without any weapon. In most cases we fight with our
                        bodies - hands, feet, elbows, etc. - to defeat opponents. However, in some cases, in accordance
                        with circumstances, we may also use weapons (such as Bo, Sai, Nunchaku, Tonfa, Weeku, Kama,
                        etc.).</p>

                    <p>People often misunderstand karate. When they see someone breaking five wooden boards or a few
                        pieces of roof tile by his or her fist, they think it is a main part of karate. Of course, it is
                        not a main part of karate but a trivial part of karate. Like other fighting arts, the truth of
                        karate or Tao of karate can be understood and mastered at the ultimate goal which is beyond
                        teachings and impossible to describe by words.</p>

                    <li>How the martial arts was introduced to Ryukyu</li>

                    <p>The name "karate" is a special term in Ryukyu. Karate originated from Chinese kung fu. We have
                        few books on the origin of Chinese kung fu, so we cannot conclude immediately, but according to
                        a theory, the martial arts originated in central Asia and the area around Turkey when the
                        ancient civilization was developed. And then it was introduced to China gradually.</p>

                    <p>However, we still have another theory. It says that about 5,000 years ago Chinese kung fu
                        originated at the age of Yellow Emperor (Emperor Huang) who built the brilliant culture at the
                        Yellow River basin. Anyway, it is not difficult to imagine that the prototype of martial arts
                        was born by the fighting spirit for struggle which human beings possess by nature. For example,
                        most styles of Chinese kung fu were created by mimicking fights of animals or birds. You can see
                        it from the styles' names such as Tiger Style, Lion Style, Monkey Style, Dog Style, Crane Style
                        and so on. In the age a little later, Chinese kung fu split into Southern school and Northern
                        school. Moreover, each school split into Neijia and Waijia.</p>

                    <p>The characteristic of Neijia is mainly softness, and it is a defensive fighting art. Wudang kung
                        fu (Tai chi for example) is typical of Neijia. The characteristic of Waijia is mainly hardness,
                        and it is an aggressive fighting art. Shaolin kung fu is typical of Waijia, which was created at
                        Shaolin Temple in Songshang Mountain, Henan (Honan) province. And later, in the ages of Tang
                        dynasty and Song dynasty, we can find many kung fu warriors at the height of their success.</p>

                    <p>When we consider how karate was introduced to Ryukyu (Okinawa), we have various opinions without
                        any historical evidence. We have not yet come to a correct conclusion on this matter. There are
                        three main opinions, namely "Thirty-six Chinese Immigrants", "Oshima Notes" and "Importation in
                        Keicho Period". Simple explanations of each opinion follow.</p>

                    <p>(1) Thirty-six Chinese Immigrants: In 1392 (Ming dynasty in China), thirty-six Chinese immigrants
                        came to Ryukyu. At that time karate was introduced to Ryukyu by Chinese immigrants.</p>

                    <p>(2) Oshima Notes: In 1762, the merchant ship of the Ryukyu Kingdom was caught in a heavy storm on
                        the way to Satsuma (Kagoshima prefecture now), and cast ashore on the coast of Oshima, Tosa
                        (Kochi prefecture now). Shiohira Pechin, a high rank official of the ship, was an intelligent
                        person. He was helped by Choki Tobe, an intellectual who lived in Oshima. Tobe wrote down
                        Shiohira's interesting stories about the Ryukyu Kingdom. His notes were called "Oshima Notes".
                        The 3rd volume of "Oshima Notes" says: "Koshankun, a kung fu warrior, came from China to Ryukyu
                        (Okinawa) bringing his disciples with him." According to the Notes, at that time people called
                        the martial arts "Kumiaijutsu" instead of karate. These notes are the most reliable literature
                        on karate.</p>

                    <p>(3) Importation in Keicho Period: In 1609 (14th year of Keicho period), the Shimazu clan of
                        Satsuma (Kagoshima prefecture now) invaded the Ryukyu Kingdom, and they prohibited possessing
                        weapons by people of Ryukyu. Some believe that karate was created spontaneously due to the cruel
                        oppression by Satsuma. The others insist that karate was not a domestic creation but was
                        imported from China. I think it is reasonable to consider that karate was a fusion of martial
                        arts from China and "Te," a native martial arts which had already existed, so karate was
                        developed remarkably and even today it is still improved rationally and developed. We have a few
                        different opinions on origin of karate, but they are popular misconceptions and not worth
                        listening to.</p>

                    <p>As mentioned above, so far we do not have any definite and convincing opinion yet. Anyway, karate
                        has been developed, modified and improved for so many years.</p>

                    <li>Karate circles in the past</li>

                    <p>We also do not know the origin of the name "karate", but it is true that the name "karate" was
                        made recently. In the old days it was called "Te". At that time people used to practice karate
                        secretly, and masters taught a few advanced kata out of all the kata only to his best disciple.
                        If he had no suitable disciple, he never taught them to anyone, and eventually such kata have
                        completely died out. As a result, there are many kata which were not handed down.</p>

                    <p>In about middle of the Meiji period (1868-1912), prominent karate masters abolished the old way
                        of secrecy. Karate was opened to the public, so it was soon recognized by society. It was the
                        dawn in the development of karate. In accordance with the rapidly progressing culture, karate
                        was also recognized as physical education, and it was adopted as one of the teaching subjects at
                        school. Therefore, at last karate has won social approval.</p>

                    <li>How we teach karate at present</li>

                    <p>According to oral history, in the old days, the teaching policy of karate put emphasis on
                        self-defense techniques. With just a motto of "no first attack in karate," teachers showed their
                        students the moral aspects. However, I've heard that in reality they tended to neglect such
                        moral principles. So gradually the teaching policy was improved with the change of the
                        times.</p>

                    <p>Now we have discontinued and abolished the wrong tradition of so-called "body first, and mind
                        second", and we have made our way toward Tao of fighting arts or the truth of karate. Eventually
                        we have obtained the correct motto "mind first, and body second," which means karate and Zen are
                        the same.</p>

                    <p>Those who are engaged in teaching karate in Okinawa prefecture and outside Okinawa prefecture at
                        present are as follows (in random order):</p>

                    <h3>In Okinawa prefecture:</h3>

                    <p>Kentsu Yabu, Chomo Hanashiro, Chotoku Kyan, Anbun Tokuda, Juhatsu Kyoda, Choshin Chibana, Jinsei
                        Kamiya, Shinpan Shiroma, Seiko Higa, Kamado Nakasone, Jin-an Shinzato, Chojun Miyagi</p>

                    <p>Outside Okinawa prefecture:</p>

                    <p>Gichin Funakoshi, Choki Motobu, Kenwa Mabuni, Masaru Sawayama, Sanyu Sakai, Moden Yabiku,
                        Jizaburo Miki, Yasuhiro Konishi, Shinji Sato, Mizuine Mutsu, Kamesuke Higaonna, Shinjun Otsuka,
                        Shin Taira, Koki Shiroma, Kanbun Uechi</p>

                    <li>About karate styles or Ryu</li>

                    <p>There are various opinions about Ryu or styles of karate in Ryukyu (Okinawa), but they are just
                        guesses without any definite research or evidence. With regard to this matter, we feel as if we
                        are groping in the dark.</p>

                    <p>According to popular opinion, we can categorize karate into two styles: Shorin-Ryu and
                        Shorei-Ryu. They (traditional view) insist that the former is fit for a stout person, while the
                        latter for a slim person. However, such an opinion proved to be false by many studies. In the
                        mean time, there is only one opinion we can trust. It is as follows: In 1828 (Qing or Ching
                        dynasty in China), our ancestors inherited a kung fu style of Fujian province in China. They
                        continued their studies and formed Goju-Ryu karate. Even today, there still exists an orthodox
                        group which inherited genuine and authentic Goju-Ryu karate.</p>

                    <li>The features of karate.</li>

                    <p>Some good points of karate are as follows:</p>

                    <p>(1) A large place or a spacious area is not required for practicing karate.</p>

                    <p>(2) You can practice karate by yourself. You can also do it together with other karate members by
                        forming a group.</p>

                    <p>(3) You don't have to spend many hours in practicing karate.</p>

                    <p>(4) You can choose Kata suitable for your physical strength and practice it regardless of age and
                        gender.</p>

                    <p>(5) Without spending much money, you can practice karate with simple equipment (such as Makiwara)
                        or without it.</p>

                    <p>(6) Karate is very effective as a means of health promotion. There are many karateka who are
                        healthy and live long.</p>

                    <p>(7) As a result of training in mind and body, you can cultivate your character and acquire
                        indomitable spirit.</p>


                    <li>The future of karate-do</li>

                    <p>The days when karate was taught secretly is over, and the new age has come in which we practice
                        and study karate publicly and officially. Therefore, the future of karate-do is bright. Taking
                        this opportunity, we should stop advertising karate as if it were a mysterious and magical
                        fighting art on a small island called Ryukyu.</p>

                    <p>We should open karate to the public and receive criticism, opinions and studies from other
                        prominent fighting artists. In the future, we should invent complete protectors for a safe
                        karate tournament like other fighting arts, so that karate can become one of the Japanese
                        fighting arts.</p>

                    <p>Nowadays karate-do has become popular all over Japan, where many people study karate-do very
                        hard. Even outside Japan, karate-do is popular. There is a man who graduated from university in
                        Tokyo. He is now propagating and studying karate-do in Europe. In May 1934, I was invited to
                        propagate and teach karate-do in Hawaii, U.S.A. by Okinawans there and a newspaper company.
                        Karate clubs have been established in Hawaii since then. As mentioned above, now karate-do has
                        become not only a Japanese martial art but also an international martial art.</p>

                    <li>The teaching method of karate</li>

                    <p>As each person has his or her distinctive character, the muscle development is different
                        depending on his or her muscle use. Therefore, at first, we do "Preparatory Exercises" to
                        develop our muscles so that we can practice karate exercises easier, and then "Fundamental
                        Kata", "Supplementary Exercise", "Kaishu Kata" and "Kumite Training." We teach karate in this
                        way.</p>
                </ol>
                <p><strong>Each outline is as follows:</strong></p>

                <p>(1) Preparatory Exercises: We exercise each muscle of our body in order to enhance its flexibility,
                    strength and endurance, and then we practice the fundamental Kata, namely Sanchin, Tensho and
                    Naifanchi. We do these preparatory exercises again after practice of kata to relax our muscles. And
                    we take a breathing exercise and take a rest quietly.</p>

                <p>(2) Fundamental Kata: Sanchin, Tensho and Naifanchi are the fundamental kata. Through practicing
                    them, we can develop correct posture. We can inhale and exhale correctly. We can adjust increasing
                    or decreasing our power harmoniously. We can develop a powerful physique and the strong will of a
                    warrior.</p>

                <p>(3) Supplementary Exercises: These exercises enable us to learn and perform Kaishu Kata well. We
                    exercise each part of our body with a particular movement. We also practice with various equipment
                    to enhance our outer whole strength and particular part strength.</p>

                <p>(4) Kaishu Kata (Kata except Fundamental Kata): Nowadays we have about twenty or thirty kinds of
                    kata, and their names vary depending on their creators. Kata has techniques of defense and offense
                    which are connected appropriately. It has various directions of the movements and it is something
                    like gymnastics. We should perform Kata by using power of the mind and body in accordance with its
                    technical purpose so that we can learn the principle of untying and tying.</p>

                <p>(5) Kumite Training: We untie Kaishu Kata which we already learned, and we study techniques of
                    defense and offense in Kaishu Kata. Understanding its technical purpose, we practice the techniques
                    of attack and defense with fighting spirit like a real situation.</p>


                <p><strong>I summarize as follows:</strong></p>

                <p>We develop the interaction of mind and body from the fundamental Kata, Sanchin, Tensho and Naihanchi.
                    We develop the spirit of martial arts by acquiring fighting techniques through practicing Kaishu
                    Kata and Kumite training correctly.</p>

                <p>Chojun Miyagi.</p>

                <p>The following translation is from the website of Sanzinsoo (sanzinsoo@hotmail.com) entitled
                    Sanzinsoo's Okinawan Gojuryu Karate. Translation copyright © Sanzinsoo.</p>

            </Layout>);
    }
}

export default RyukyuKenpoKarateDoGaiyo;
